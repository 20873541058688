import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83af0b28 = () => interopDefault(import('../pages/-404.vue' /* webpackChunkName: "" */))
const _95dbf27c = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "" */))
const _bad59aac = () => interopDefault(import('../pages/exercise-tools/index.vue' /* webpackChunkName: "" */))
const _053753fe = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "" */))
const _3925632a = () => interopDefault(import('../business/pages/information/index.vue' /* webpackChunkName: "" */))
const _b5d617c8 = () => interopDefault(import('../business/pages/searchResult/index.vue' /* webpackChunkName: "" */))
const _709ecb42 = () => interopDefault(import('../business/pages/learn/_course_id.vue' /* webpackChunkName: "" */))
const _4e098656 = () => interopDefault(import('../business/pages/product/_id.vue' /* webpackChunkName: "" */))
const _2c8a28f6 = () => interopDefault(import('../business/pages/sublist/_course_id.vue' /* webpackChunkName: "" */))
const _22056cc3 = () => interopDefault(import('../business/pages/suggest/index.vue' /* webpackChunkName: "" */))
const _753520b7 = () => interopDefault(import('../business/pages/suggest/success/index.vue' /* webpackChunkName: "" */))
const _7b952078 = () => interopDefault(import('../business/pages/help/index.vue' /* webpackChunkName: "" */))
const _5ef56cfb = () => interopDefault(import('../business/pages/help/index/index.vue' /* webpackChunkName: "" */))
const _6bac6e7b = () => interopDefault(import('../business/pages/help/index/detail/index.vue' /* webpackChunkName: "" */))
const _98f162bc = () => interopDefault(import('../business/pages/help/index/lesson/index.vue' /* webpackChunkName: "" */))
const _c1dc4eac = () => interopDefault(import('../business/pages/help/index/lecture/index.vue' /* webpackChunkName: "" */))
const _0b0886b0 = () => interopDefault(import('../business/pages/help/index/list/_id.vue' /* webpackChunkName: "" */))
const _80f34df8 = () => interopDefault(import('../pages/50yuanshujiake/index.vue' /* webpackChunkName: "pages/50yuanshujiake/index" */))
const _6d6acefd = () => interopDefault(import('../pages/aboutus/index.vue' /* webpackChunkName: "pages/aboutus/index" */))
const _1904423c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _1b0d2bbf = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _c44eae98 = () => interopDefault(import('../pages/account/coin.vue' /* webpackChunkName: "pages/account/coin" */))
const _3868d837 = () => interopDefault(import('../pages/account/coin/index.vue' /* webpackChunkName: "pages/account/coin/index" */))
const _07d06b3f = () => interopDefault(import('../pages/account/coin/mall.vue' /* webpackChunkName: "pages/account/coin/mall" */))
const _982ff668 = () => interopDefault(import('../pages/account/coupon/index.vue' /* webpackChunkName: "pages/account/coupon/index" */))
const _14816ccb = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _f42d7054 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _4c4bd44e = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _6875083b = () => interopDefault(import('../pages/account/profile/address.vue' /* webpackChunkName: "pages/account/profile/address" */))
const _291a80f4 = () => interopDefault(import('../pages/account/profile/changePassword.vue' /* webpackChunkName: "pages/account/profile/changePassword" */))
const _7e161f65 = () => interopDefault(import('../pages/account/profile/changePhone.vue' /* webpackChunkName: "pages/account/profile/changePhone" */))
const _467f0339 = () => interopDefault(import('../pages/account/study/index.vue' /* webpackChunkName: "pages/account/study/index" */))
const _17af4b1e = () => interopDefault(import('../pages/account/components/CourseCard.vue' /* webpackChunkName: "pages/account/components/CourseCard" */))
const _4f77f8ef = () => interopDefault(import('../pages/account/components/ListNoData.vue' /* webpackChunkName: "pages/account/components/ListNoData" */))
const _2c50b4de = () => interopDefault(import('../pages/account/coupon/suitableList/index.vue' /* webpackChunkName: "pages/account/coupon/suitableList/index" */))
const _6f3faa17 = () => interopDefault(import('../pages/account/orders/oldIndexCopy.vue' /* webpackChunkName: "pages/account/orders/oldIndexCopy" */))
const _c6d38038 = () => interopDefault(import('../pages/account/orders/detail/_order_id.vue' /* webpackChunkName: "pages/account/orders/detail/_order_id" */))
const _245cd77a = () => interopDefault(import('../pages/busy.vue' /* webpackChunkName: "pages/busy" */))
const _6020adcc = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _65781f97 = () => interopDefault(import('../pages/index.htm/index.vue' /* webpackChunkName: "pages/index.htm/index" */))
const _437c278b = () => interopDefault(import('../pages/index.html/index.vue' /* webpackChunkName: "pages/index.html/index" */))
const _2ff03b38 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4b046d6f = () => interopDefault(import('../pages/ourdesc/index.vue' /* webpackChunkName: "pages/ourdesc/index" */))
const _2af53436 = () => interopDefault(import('../pages/policy/index.vue' /* webpackChunkName: "pages/policy/index" */))
const _93b5d6ac = () => interopDefault(import('../pages/protocal/index.vue' /* webpackChunkName: "pages/protocal/index" */))
const _2bf663c3 = () => interopDefault(import('../pages/qinglang/index.vue' /* webpackChunkName: "pages/qinglang/index" */))
const _e024607e = () => interopDefault(import('../pages/qualification/index.vue' /* webpackChunkName: "pages/qualification/index" */))
const _da99782c = () => interopDefault(import('../pages/refresh.vue' /* webpackChunkName: "pages/refresh" */))
const _17fc592c = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _5a9c1e1b = () => interopDefault(import('../pages/study/index.vue' /* webpackChunkName: "pages/study/index" */))
const _7a5abe73 = () => interopDefault(import('../pages/category/positionPopup.vue' /* webpackChunkName: "pages/category/positionPopup" */))
const _0f93016c = () => interopDefault(import('../pages/course/detail.htm/index.vue' /* webpackChunkName: "pages/course/detail.htm/index" */))
const _b99e28d2 = () => interopDefault(import('../pages/course/list.htm/index.vue' /* webpackChunkName: "pages/course/list.htm/index" */))
const _4db3c151 = () => interopDefault(import('../pages/policy/policy.js' /* webpackChunkName: "pages/policy/policy" */))
const _bed7feb8 = () => interopDefault(import('../pages/slogan/help/index.vue' /* webpackChunkName: "pages/slogan/help/index" */))
const _1d1fef70 = () => interopDefault(import('../pages/study/calendar/index.vue' /* webpackChunkName: "pages/study/calendar/index" */))
const _2db9d1d0 = () => interopDefault(import('../pages/study/mycourses/index.vue' /* webpackChunkName: "pages/study/mycourses/index" */))
const _3c2c7c1a = () => interopDefault(import('../pages/study/tasks/index.vue' /* webpackChunkName: "pages/study/tasks/index" */))
const _41a54956 = () => interopDefault(import('../pages/live/clazz/join/index.vue' /* webpackChunkName: "pages/live/clazz/join/index" */))
const _b6a73bf8 = () => interopDefault(import('../pages/study/mycourses/detail/_course_id.vue' /* webpackChunkName: "pages/study/mycourses/detail/_course_id" */))
const _1bf09596 = () => interopDefault(import('../pages/live/playbackList/_id.vue' /* webpackChunkName: "pages/live/playbackList/_id" */))
const _3293ea15 = () => interopDefault(import('../pages/pay/succeed/_id.vue' /* webpackChunkName: "pages/pay/succeed/_id" */))
const _1dc82d0a = () => interopDefault(import('../pages/teacher/detail/_id.vue' /* webpackChunkName: "pages/teacher/detail/_id" */))
const _24120790 = () => interopDefault(import('../pages/apply/_product_id.vue' /* webpackChunkName: "pages/apply/_product_id" */))
const _5da3bbd7 = () => interopDefault(import('../pages/html/_id.vue' /* webpackChunkName: "pages/html/_id" */))
const _96d5c016 = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _66489821 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/study*",
    component: _83af0b28,
    name: "study*"
  }, {
    path: "/category/:subject_id?/:subject_type?",
    component: _95dbf27c,
    name: "category*"
  }, {
    path: "/exercise-tools/:category_id/:section_id",
    component: _bad59aac,
    name: "exercise-tools*"
  }, {
    path: "/topic/:biz_question_id/:synteach_material_id/:last_category_id",
    component: _053753fe,
    name: "topic*"
  }, {
    path: "/information/:information_id/:subject_id",
    component: _3925632a,
    name: "information*"
  }, {
    path: "/searchResult",
    component: _b5d617c8,
    name: "searchResult"
  }, {
    path: "/learn/:course_id?",
    component: _709ecb42,
    name: "learn-course_id"
  }, {
    path: "/product/:id?",
    component: _4e098656,
    name: "product-id"
  }, {
    path: "/sublist/:course_id?",
    component: _2c8a28f6,
    name: "sublist-course_id"
  }, {
    path: "/suggest",
    component: _22056cc3,
    name: "suggest"
  }, {
    path: "/suggest/success",
    component: _753520b7,
    name: "suggest-success"
  }, {
    path: "/help",
    component: _7b952078,
    children: [{
      path: "",
      component: _5ef56cfb,
      name: "help-index"
    }, {
      path: "detail",
      component: _6bac6e7b,
      name: "help-index-lesson"
    }, {
      path: "lesson",
      component: _98f162bc,
      name: "help-index-lesson"
    }, {
      path: "lecture",
      component: _c1dc4eac,
      name: "help-index-lecture"
    }, {
      path: "list/:id?",
      component: _0b0886b0,
      name: "help-index-list-id"
    }]
  }, {
    path: "/50yuanshujiake",
    component: _80f34df8,
    name: "50yuanshujiake"
  }, {
    path: "/aboutus",
    component: _6d6acefd,
    name: "aboutus"
  }, {
    path: "/account",
    component: _1904423c,
    children: [{
      path: "",
      component: _1b0d2bbf,
      name: "account"
    }, {
      path: "coin",
      component: _c44eae98,
      children: [{
        path: "",
        component: _3868d837,
        name: "account-coin"
      }, {
        path: "mall",
        component: _07d06b3f,
        name: "account-coin-mall"
      }]
    }, {
      path: "coupon",
      component: _982ff668,
      name: "account-coupon"
    }, {
      path: "orders",
      component: _14816ccb,
      name: "account-orders"
    }, {
      path: "profile",
      component: _f42d7054,
      children: [{
        path: "",
        component: _4c4bd44e,
        name: "account-profile"
      }, {
        path: "address",
        component: _6875083b,
        name: "account-profile-address"
      }, {
        path: "changePassword",
        component: _291a80f4,
        name: "account-profile-changePassword"
      }, {
        path: "changePhone",
        component: _7e161f65,
        name: "account-profile-changePhone"
      }]
    }, {
      path: "study",
      component: _467f0339,
      name: "account-study"
    }, {
      path: "components/CourseCard",
      component: _17af4b1e,
      name: "account-components-CourseCard"
    }, {
      path: "components/ListNoData",
      component: _4f77f8ef,
      name: "account-components-ListNoData"
    }, {
      path: "coupon/suitableList",
      component: _2c50b4de,
      name: "account-coupon-suitableList"
    }, {
      path: "orders/oldIndexCopy",
      component: _6f3faa17,
      name: "account-orders-oldIndexCopy"
    }, {
      path: "orders/detail/:order_id?",
      component: _c6d38038,
      name: "account-orders-detail-order_id"
    }]
  }, {
    path: "/busy",
    component: _245cd77a,
    name: "busy"
  }, {
    path: "/category",
    component: _95dbf27c,
    name: "category"
  }, {
    path: "/download",
    component: _6020adcc,
    name: "download"
  }, {
    path: "/exercise-tools",
    component: _bad59aac,
    name: "exercise-tools"
  }, {
    path: "/index.htm",
    component: _65781f97,
    name: "index.htm"
  }, {
    path: "/index.html",
    component: _437c278b,
    name: "index.html"
  }, {
    path: "/login",
    component: _2ff03b38,
    name: "login"
  }, {
    path: "/ourdesc",
    component: _4b046d6f,
    name: "ourdesc"
  }, {
    path: "/policy",
    component: _2af53436,
    name: "policy"
  }, {
    path: "/protocal",
    component: _93b5d6ac,
    name: "protocal"
  }, {
    path: "/qinglang",
    component: _2bf663c3,
    name: "qinglang"
  }, {
    path: "/qualification",
    component: _e024607e,
    name: "qualification"
  }, {
    path: "/refresh",
    component: _da99782c,
    name: "refresh"
  }, {
    path: "/search",
    component: _17fc592c,
    name: "search"
  }, {
    path: "/study",
    component: _5a9c1e1b,
    name: "study"
  }, {
    path: "/topic",
    component: _053753fe,
    name: "topic"
  }, {
    path: "/category/positionPopup",
    component: _7a5abe73,
    name: "category-positionPopup"
  }, {
    path: "/course/detail.htm",
    component: _0f93016c,
    name: "course-detail.htm"
  }, {
    path: "/course/list.htm",
    component: _b99e28d2,
    name: "course-list.htm"
  }, {
    path: "/policy/policy",
    component: _4db3c151,
    name: "policy-policy"
  }, {
    path: "/slogan/help",
    component: _bed7feb8,
    name: "slogan-help"
  }, {
    path: "/study/calendar",
    component: _1d1fef70,
    name: "study-calendar"
  }, {
    path: "/study/mycourses",
    component: _2db9d1d0,
    name: "study-mycourses"
  }, {
    path: "/study/tasks",
    component: _3c2c7c1a,
    name: "study-tasks"
  }, {
    path: "/live/clazz/join",
    component: _41a54956,
    name: "live-clazz-join"
  }, {
    path: "/study/mycourses/detail/:course_id?",
    component: _b6a73bf8,
    name: "study-mycourses-detail-course_id"
  }, {
    path: "/live/playbackList/:id?",
    component: _1bf09596,
    name: "live-playbackList-id"
  }, {
    path: "/pay/succeed/:id?",
    component: _3293ea15,
    name: "pay-succeed-id"
  }, {
    path: "/teacher/detail/:id?",
    component: _1dc82d0a,
    name: "teacher-detail-id"
  }, {
    path: "/apply/:product_id?",
    component: _24120790,
    name: "apply-product_id"
  }, {
    path: "/html/:id?",
    component: _5da3bbd7,
    name: "html-id"
  }, {
    path: "/video/:id?",
    component: _96d5c016,
    name: "video-id"
  }, {
    path: "/",
    component: _66489821,
    name: "index"
  }, {
    path: "/learn1/:course_id?",
    component: _709ecb42,
    name: "learn1-course_id"
  }, {
    path: "/learn2/:course_id?",
    component: _709ecb42,
    name: "learn2-course_id"
  }, {
    path: "/learn3/:course_id?",
    component: _709ecb42,
    name: "learn3-course_id"
  }, {
    path: "/clazz/:course_id?",
    component: _709ecb42,
    name: "clazz-course_id"
  }, {
    path: "/clazz1/:course_id?",
    component: _709ecb42,
    name: "clazz1-course_id"
  }, {
    path: "/clazz2/:course_id?",
    component: _709ecb42,
    name: "clazz2-course_id"
  }, {
    path: "/class/:course_id?",
    component: _709ecb42,
    name: "class-course_id"
  }, {
    path: "/class1/:course_id?",
    component: _709ecb42,
    name: "class1-course_id"
  }, {
    path: "/class2/:course_id?",
    component: _709ecb42,
    name: "class2-course_id"
  }, {
    path: "/class3/:course_id?",
    component: _709ecb42,
    name: "class3-course_id"
  }, {
    path: "/download*",
    component: _6020adcc,
    name: "download-all"
  }, {
    path: "*",
    component: _83af0b28,
    name: "404"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
